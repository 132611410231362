import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import GridHeader from "./gridHeader";
import GridFooter from "./gridFooter";
import CustomNoRowsOverlay from "./cutomNoRowsOverlay";
import { ModuleRegistry } from "@ag-grid-community/core";

import { ClientSideRowModelModule } from "ag-grid-community";
import {
  MenuModule,
  MultiFilterModule,
  SetFilterModule,
} from "ag-grid-enterprise";
import { defaultNoRecordFound } from "../../interface/comman.constant";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  MultiFilterModule,
  SetFilterModule,
]);

const GridClientComponent = React.forwardRef((props: any, ref) => {
  let timer: any;
  const [gridKey, setGridKey] = useState<string>(`grid-key-${Math.random()}`);
  const [columnValues, SetColumnValues]: any = useState();

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);
  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => <span>{defaultNoRecordFound}</span>,
    };
  }, []);

  const [finalHeight, setFinalHeight] = useState<any>(400);
  const handleResize = () => {
    clearTimeout(timer);

    timer = setTimeout(function () {
      var header = document.getElementsByClassName("header")[0].clientHeight;
      var headerTitle =
        document.getElementsByClassName("content_header")[0].clientHeight;
      var gridSearch =
        document.getElementsByClassName("content_body_head")[0].clientHeight;
      var gridPagination =
        document.getElementsByClassName("ag-paging-panel")[0].clientHeight;
      var footer = document.getElementsByClassName("footer")[0].clientHeight;
      var totalHeight =
        header + headerTitle + gridSearch + gridPagination + footer;
      var finalHeight = window.innerHeight - totalHeight;
      setFinalHeight(finalHeight);
    }, 500);
  };

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    props.popupSave(event, columnValues);
    SetColumnValues(columnValues);
  };

  const onGridSizeChanged = useCallback(
    (params: any) => {
      if (props?.autoSizeStrategy) return;

      // get the current grids width
      var gridWidth = document.querySelector(".ag-body-viewport")!.clientWidth;
      // keep track of which columns to hide/show
      var columnsToShow = [];
      var columnsToHide = [];
      // iterate over all columns (visible or not) and work out
      // now many columns can fit (based on their minWidth)
      var totalColsWidth = 0;
      // var allColumns = params.api.getColumns();
      var allColumns = columnValues;
      if (allColumns && allColumns.length > 0) {
        for (var i = 0; i < allColumns.length; i++) {
          var column = allColumns[i];
          totalColsWidth += column.getMinWidth() || 0;
          if (totalColsWidth > gridWidth) {
            columnsToHide.push(column.getColId());
          } else {
            columnsToShow.push(column.getColId());
          }
        }
      }
      // show/hide columns based on current grid width
      params.api.setColumnsVisible(columnsToShow, true);
      params.api.setColumnsVisible(columnsToHide, false);
      // wait until columns stopped moving and fill out
      // any available space to ensure there are no gaps
      window.setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 10);
    },
    [window]
  );

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", () => {
      handleResize();
    });
    return () =>
      window.removeEventListener("resize", () => {
        handleResize();
      });
  }, [finalHeight]);
  const gridStyle = useMemo(
    () => ({
      height: props.isAutoHeight ? "100%" : finalHeight + "px",
      width: "100%",
    }),
    [finalHeight]
  );

  const onGridReady = (params: any) => {
    props.onGridReady(params);
  };

  return (
    <>
      <div className="ag-theme-quartz">
        {!props.hideheader ? (
          <GridHeader
            kitFilter={props.kitFilter}
            kitFilterChange={props.kitFilterChange}
            ref={ref}
            gridRef={props.gridRef}
            columnDefs={props.colDefs}
            handleSave={popupSave}
            globalSearch={props.globalSearch}
            onExport={props.onExport}
            gridfilterbutton={props.gridfilterbutton}
            hidesearch={props.hidesearch}
            onClearFilter={props.onClearFilter}
          ></GridHeader>
        ) : (
          <></>
        )}
        <div
          style={gridStyle}
          className={
            props.isModalGrid === true
              ? "content_body_inner ag-grid-wrapper"
              : "content_body_inner ag-grid-wrapper"
          }
        >
          <AgGridReact
            key={gridKey}
            ref={props.gridRef}
            rowData={props.rowData}
            columnDefs={props.colDefs}
            defaultColDef={props.defaultColDef}
            suppressExcelExport={true}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            rowSelection={"multiple"}
            rowGroupPanelShow={"always"}
            pivotPanelShow={"always"}
            onGridReady={onGridReady}
            pagination={false}
            autoSizeStrategy={
              props.autoSizeStrategy
                ? props.autoSizeStrategy
                : { type: "fitGridWidth" }
            }
            paginationPageSize={props.paginationPageSize}
            paginationPageSizeSelector={props.paginationPageSizeSelector}
            onGridSizeChanged={onGridSizeChanged}
            onPaginationChanged={props.paginationChange}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            //  domLayout="autoHeight"
          />
        </div>
        <GridFooter
          gridData={props.gridData}
          setGridData={props.setGridData}
        ></GridFooter>
      </div>
    </>
  );
});

export default GridClientComponent;
