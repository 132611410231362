import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { GetSchedult_II_III_V_ChartDetail } from "../../../store/api/apiSlice";
import { useAppDispatch } from "../../../store/hooks";
import GridComponent from "../../../components/grid/gridComponent";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import { formateIds, gridAlignment } from "../../../interface/comman.constant";
import { getTableFields } from "../../../helpers/getTableFields";

function Schedule_III_III_V_DetailModal(props: any) {
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const [gridApi, setGridApi] = useState<any>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "CountofScheduleIIandScheduleIII-V.csv",
    URL: "Dashboard/GetSchedult_II_III_V_ChartDetail",
  });
  const columnitems = [
    {
      headerName: "Order#",
      field: "orderNumber",
      hide: false,
      comparator: customComparator,
      cellStyle: { cursor: "pointer" },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Process Date",
      field: "processDate",
      hide: false,
      comparator: customComparator,
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    },
    {
      headerName: "Product",
      field: "product",
      hide: false,
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "DEA Class",
      field: "deaClass",
      hide: false,
      comparator: customComparator,

      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Quantity",
      field: "quantity",
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
  ];
  const [colDefs, setColDefs] = useState(columnitems);
  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        refreshData();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      refreshData();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.globalSearch,
    gridData.sortOrder,
    gridData.sortField,
    gridApi,
    refreshPage,
  ]);

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const refreshData = () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              pageSize: gridData.pageSize,
              DurationType: parseInt(props.DurationType),
              Duration: props.Duration, // 2024
              DefaultDeaClass: props.DefaultDeaClass, // key
              GlobalSearch: gridData.globalSearch,
              SortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              CustomerIds: formateIds(userAccounts),
              filterOptions: getTableFields(params.request.filterModel),
            };
            
            dispatch(GetSchedult_II_III_V_ChartDetail(data)).then(
              (result: any) => {
                updateGridData("totalRows", result?.payload?.data?.totalCount);
                updateGridData(
                  "rows",
                  result?.payload?.data?.schedule_II_III_V_ChartDetail
                );
                if (
                  result?.payload?.data?.schedule_II_III_V_ChartDetail
                    ?.length === 0
                ) {
                  gridApi.showNoRowsOverlay();
                  params.success({
                    rowData: [],
                    rowCount: 0,
                  });
                } else {
                  gridApi!.hideOverlay();
                  params.success({
                    rowData:
                      result?.payload?.data?.schedule_II_III_V_ChartDetail,
                    rowCount: result?.payload?.data?.totalCount,
                  });
                }
              }
            );
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };
  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    localStorage.setItem(
      "Schedult_II_III_V_ChartDetailCol",
      JSON.stringify(columnValues)
    );

    setColDefs(columnValues);
    myRef?.current.handleClose();
  };
  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };
  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Modal
      show={props.show}
      size="lg"
      onHide={props.handleClose}
      className="custom_modal "
    >
      <Modal.Header className="custom_modal_header">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left">
            COUNT OF SCHEDULE II & SCHEDULE III-V DETAILS
          </div>
          <div className="custom_modal_header_title_right">
            <Button variant="link" onClick={props.handleClose}>
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_body">
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </Modal.Body>
    </Modal>
  );
}

export default Schedule_III_III_V_DetailModal;
