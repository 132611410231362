import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { usePermission } from "../helpers/hooks/usePermissions";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import Layout from "../pages/shared/layout/layout";
import PageNotFound from "../pages/shared/404/pageNotFound";
import StartReturn from "../pages/admin/serviceModule/startReturn/startReturn";
import RequestAPharmaWasteKit from "../pages/admin/serviceModule/requestKits/requestAPharmaWasteKit";
import Help from "../pages/shared/help/help";
import UserManagement from "../pages/admin/SettingsModule/userManagement/userManagement";
import MyProfile from "../pages/auth/profile/myProfile";
import DisbursementSummary from "../pages/admin/reporting/financialReports/disbursementSummary";
import InvoiceSummary from "../pages/admin/reporting/financialReports/invoiceSummary";
import ServiceFeeGuarantees from "../pages/admin/reporting/financialReports/serviceFeeGuarantees";
import ViewAllReports from "../pages/admin/reporting/viewAllReports/viewAllReports";
import CompleteSetup from "../pages/auth/setup/completeSetup";
import ScrollToTop from "../pages/shared/scrollTop/scrollToTop";
import Notifications from "../pages/shared/notifications/notifications";
import RequestOnsiteService from "../pages/admin/serviceModule/requestOnsiteService/requestOnsiteService";
import OrderLineDetails from "../pages/admin/reporting/returnReports/orderLineDetails";
import DestructionForm from "../pages/admin/reporting/complianceReports/destructionForm";
import EPACompliance from "../pages/admin/reporting/complianceReports/epaCompliance";
import DisbursementDetails from "../pages/admin/reporting/financialReports/disbursementDetails";
import UpcomingReturns from "../pages/admin/reporting/returnReports/upcomingReturns";
import ViewHistory from "../pages/admin/serviceModule/startReturn/viewHistory";
import ChangePasswordSetup from "../pages/auth/profile/changePasswordSetup";
import TrackShipment from "../pages/admin/reporting/returnReports/trackShipment";
import OrderHistory from "../pages/admin/reporting/returnReports/orderHistory";
import DestructionManifests from "../pages/admin/reporting/returnReports/destructionManifests";
import ServiceFeeGuaranteesDetails from "../pages/admin/reporting/financialReports/serviceFeeGuaranteesDetails";
import InventoryReports from "../pages/admin/reporting/returnReports/inventoryReports";
import OverstockSummary from "../pages/admin/reporting/returnReports/overstockSummary";
import TransactionSummary from "../pages/admin/reporting/financialReports/transactionSummary";
import FormDetail from "../pages/admin/reporting/complianceReports/formDetail";
import InvoiceDetails from "../pages/admin/reporting/financialReports/invoiceDetails";
import AccountStatement from "../pages/admin/reporting/returnReports/accountStatement";
import RapidCredit from "../pages/admin/reporting/returnReports/rapidCredit";
import PartneredSummary from "../pages/admin/reporting/returnReports/partneredSummary";
import DisposalReturns from "../pages/admin/reporting/returnReports/disposalReturns";
import DrugLiquidation from "../pages/admin/reporting/returnReports/drugLiquidation";
import ReportManagament from "../pages/admin/reporting/reportManagement/reportManagement";
import { USER_ROLES, formateIds } from "../interface/comman.constant";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setUserPermissions } from "../store/api/apiSlice";
import { useAppDispatch } from "../store/hooks";
import AccessDenied from "../pages/shared/404/accessDenied";
import AdminLogin from "../pages/auth/setup/adminLogin";
import { useMsal } from "@azure/msal-react";
import DrugLiquidationOrderHistory from "../pages/admin/reporting/returnReports/drugLiquidationOrderHistory";
export const MainRoutes = () => {
  const dispatch = useAppDispatch();
  const permissions = usePermission(true);
  const refreshPage = useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const user = useSelector((state: any) => state?.auth?.userInfo?.data?.userDetail) || {};
  const userAccounts = useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const { instance } = useMsal();
  let activeAccount: any;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const getPermissions = () => {
    dispatch(setUserPermissions({ CustomerIds: formateIds(userAccounts) })).then((result: any) => {
      localStorage.setItem("permissions", JSON.stringify(result?.payload || {}));
    });
  };

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      getPermissions();
    }
  }, [refreshPage]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Dashboard />} />

          {!(permissions?.['role'] === 1) && (
            <>
              <Route path="/reports/view-all" element={<ViewAllReports />} />

              {permissions?.['Form41Report'] && (
                <Route path="/reports/form-41" element={<DestructionForm />} />
              )}
              {permissions?.['TransactionRegisterDisbursementSummary'] && (
                <Route path="/reports/disbursement-summary" element={<DisbursementSummary />} />
              )}
              {permissions?.['TransactionRegisterDisbursementDetail'] && (
                <Route path="/reports/disbursement-details/:id" element={<DisbursementDetails />} />
              )}
              {permissions?.['EPAComplianceNotificationReport'] && (
                <Route path="/reports/epa-compliance" element={<EPACompliance />} />
              )}
              {permissions?.['TrackAShipment'] && (
                <Route path="/reports/track-shipment" element={<TrackShipment />} />
              )}
              {permissions?.['UpcomingReturns'] && (
                <Route path="/reports/upcoming-returns" element={<UpcomingReturns />} />
              )}
              {permissions?.['TransactionRegisterInvoiceSummary'] && (
                <Route path="/reports/invoice-summary" element={<InvoiceSummary />} />
              )}
              {permissions?.['DestructionManifests'] && (
                <Route path="/reports/destruction-manifests" element={<DestructionManifests />} />
              )}
              {permissions?.['OrderLineDetail'] && (
                <Route path="/reports/order-line-details" element={<OrderLineDetails />} />
              )}
              {permissions?.['InventoryReports'] && (
                <Route path="/reports/inventory-reports" element={<InventoryReports />} />
              )}
              {permissions?.['Overstock'] && (
                <Route path="/reports/overstock-summary" element={<OverstockSummary />} />
              )}
              {permissions?.['TransactionRegisterInvoiceDetail'] && (
                <Route path="/reports/invoice-details/:id" element={<InvoiceDetails />} />
              )}
              {permissions?.['IndividualOrderSummaryRCP'] && (
                <Route path="/reports/rapid-credit/:id" element={<RapidCredit />} />
              )}
              {permissions?.['222FormDetail'] && (
                <Route path="/reports/form-222" element={<FormDetail />} />
              )}
              {permissions?.['TransactionRegisterServiceFeeGuaranteesSummary'] && (
                <Route path="/reports/servicefeeguarantees" element={<ServiceFeeGuarantees />} />
              )}
              {permissions?.['TransactionRegisterServiceFeeGuaranteeDetail'] && (
                <Route path="/reports/servicefeeguarantees-details/:id" element={<ServiceFeeGuaranteesDetails />} />
              )}
              {permissions?.['OrderHistory'] && (
                <Route path="/reports/order-history" element={<OrderHistory />} />
              )}
               {permissions?.['DrugLiquidationOrderHistory'] && (
                <Route path="/reports/drug-liquidation-order-history" element={<DrugLiquidationOrderHistory />} />
              )}
              {permissions?.['TransactionRegisterTransactionSummary'] && (
                <Route path="/reports/transaction-summary" element={<TransactionSummary />} />
              )}
              {permissions?.['MonthyAccountSummaryStatement'] && (
                <Route path="/reports/account-statement" element={<AccountStatement />} />
              )}
              <Route path="/reports/partner-summary/:id" element={<PartneredSummary />} />
              {permissions?.['DisposalReturns'] && (
                <Route path="/reports/disposal-returns" element={<DisposalReturns />} />
              )}
              {permissions?.['IndividualOrderSummaryDrugLiquidation'] && (
                <Route path="/reports/drug-liquidation/:id" element={<DrugLiquidation />} />
              )}
              {permissions?.['ReportManagement'] && (
                <Route path="/reports/reportManagement" element={<ReportManagament />} />
              )}
            </>
          )}
          {permissions?.['StartAReturn'] && (
            <Route path="/services/start-Inventoried-return" element={<StartReturn />} />
          )}
          {permissions?.['StartAReturn'] && (
            <Route path="/services/start-Inventoried-return/:id" element={<StartReturn />} />
          )}
          <Route path="/services/viewHistory" element={<ViewHistory />} />
          {permissions?.['RequestBoxAndShipSupplies'] && (
            <Route path="/services/request-supplies" element={<RequestAPharmaWasteKit />} />
          )}
          {permissions?.['RequestAPharmawasteKit'] && (
            <Route path="/services/request-pharma-kit" element={<RequestAPharmaWasteKit />} />
          )}
          {permissions?.['RequestATakebackKit'] && (
            <Route path="/services/request-take-back-kit" element={<RequestAPharmaWasteKit />} />
          )}
          {permissions?.['RequestAdditionsOnSiteService'] && (
            <Route path="/services/request-on-site-service" element={<RequestOnsiteService />} />
          )}
          {permissions?.['TrackAShipmentReport'] && (
            <Route path="/services/track-shipment" element={<TrackShipment />} />
          )}
          {permissions?.['UpcomingShipmentsReport'] && (
            <Route path="/services/upcoming-returns" element={<UpcomingReturns />} />
          )}
          
          
          {permissions?.['UserManagement'] && (
            <Route path="/settings/user-management" element={<UserManagement />} />
          )}
          {permissions?.['HelpContactDetails'] && (
            <Route path="/help" element={<Help />} />
          )}
          {permissions?.['HelpReportAnIssue'] && (
            <Route path="/help/reportIssue" element={<Help />} />
          )}
          {permissions?.['HelpPortalTraining'] && (
            (activeAccount?.username === "kashline@pharmalogistics.com") && (
              <Route path="/help/portalTraning" element={<Help />} />
            )
          )}
          <Route path="/setup" element={<CompleteSetup />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/setup/:email" element={<CompleteSetup />} />
          <Route path="/change-password/:id/:email" element={<ChangePasswordSetup />} />
          {permissions?.['MyProfile'] && (
            <Route path="/my-profile" element={<MyProfile />} />
          )}
          {permissions?.['NotificationsListOfNotifcationsReceived'] && (
            <Route path="/notifications" element={<Notifications />} />
          )}
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/login.aspx" element={<Dashboard />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
