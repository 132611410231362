import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";

function GridFilterModal(props: any) {
  const [columnValues, setcolumnValues] = useState(props.columnDefs);
  const [filtercolumnValues, setFiltercolumnValues] = useState(
    props.columnDefs
  );
  const onSearchChange = (e: any) => {
    if (e.target.value !== "") {
      const filtered = props?.columnDefs.filter((item: any) =>
        item?.headerName?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFiltercolumnValues(filtered);
    } else {
      setFiltercolumnValues(props.columnDefs);
    }
  };

  const handleCheckBoxChange = (e: any) => {
    const { value, checked } = e.target;
    const newState = columnValues?.map((obj: any) =>
      obj.field === value ? { ...obj, hide: !checked } : obj
    );
    const newStateFilter = filtercolumnValues?.map((obj: any) =>
      obj.field === value ? { ...obj, hide: !checked } : obj
    );
    setcolumnValues(newState);
    setFiltercolumnValues(newStateFilter);
  };

  useEffect(() => {
    setFiltercolumnValues(columnValues);
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="custom_modal"
    >
      <Modal.Header className="custom_modal_header">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left">
            Customize Columns
          </div>
          <div className="custom_modal_header_title_right">
            <span></span>
            <Button variant="link" onClick={props.handleClose}>
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_body">
        <div className="form_box">
          <div className="modal_search_header">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <i className="fa-solid fa-search"></i>
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Seach"
                name="filter"
                id="filter-text-box"
                onInput={(e) => onSearchChange(e)}
              />
            </InputGroup>
          </div>
          <div className="modal-search-list">
            {filtercolumnValues ? (
              filtercolumnValues?.map((item: any, index: any) => {
                return (
                  <div className="modal_search_list_item" key={index}>
                    <Form.Check
                      className="ag-header-cell-comp-wrapper"
                      id={item?.id}
                      aria-label={item?.field}
                      label={
                        item?.headerName !== null &&
                        item?.headerName !== undefined
                          ? item?.headerName
                          : item?.field
                      }
                      value={item?.field}
                      onChange={(event) => handleCheckBoxChange(event)}
                      checked={!item?.hide}
                    />
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="custom_modal_footer">
        <Button
          variant="primary"
          onClick={(event) => props.handleSave(event, columnValues)}
        >
          Save
        </Button>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GridFilterModal;
