import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import { get222FormDetails } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import {
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";

function FormDetail() {
  const gridRef = useRef<AgGridReact>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridApi, setGridApi] = useState<any>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "222FormDetail.csv",
    URL: "Reports/Get222From",
    filterOptions: [],
  });
  const columnItems = [
    {
      headerName: "222 Form Serial#",
      headerTooltip: "222 Form Serial#",
      field: "form222SerialNumber",
      hide: false,
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      type: gridAlignment.RIGHT,
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "222 Form Line#",
      headerTooltip: "222 Form Line#",
      field: "form222LineNumber",
      hide: false,
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "NDC",
      headerTooltip: "NDC",
      field: "ndc",
      hide: false,
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Inventoried Quantity",
      headerTooltip: "Inventoried Quantity",
      field: "inventoriedQuantity",
      hide: false,
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Received Quantity",
      headerTooltip: "Received Quantity",
      field: "receivedQuantity",
      hide: false,
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Discrepancy",
      headerTooltip: "Discrepancy",
      field: "discrepancy",
      hide: false,
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      field: "orderNumber",
      hide: false,
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },

    // {
    //   headerName: "Date Issued",
    //   headerTooltip: "Date Issued",
    //   field: "dateIssued",
    //   hide: false,
    //   valueFormatter: function (params: any) {
    //     return formatDatePipe(params.value);
    //   },
    //   comparator: customComparator,
    //   filter: "agDateColumnFilter",
    //   menuTabs: ["filterMenuTab"],
    //   filterParams: {
    //     suppressAndOrCondition: true,
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //   },
    // },
    // {
    //   headerName: "Expiration Date",
    //   headerTooltip: "Expiration Date",
    //   field: "expirationDate",
    //   hide: false,
    //   valueFormatter: function (params: any) {
    //     return formatDatePipe(params.value);
    //   },
    //   comparator: customComparator,
    //   filter: "agDateColumnFilter",
    //   menuTabs: ["filterMenuTab"],
    //   filterParams: {
    //     suppressAndOrCondition: true,
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //   },
    // },
    // {
    //   headerName: "Receipt Date",
    //   headerTooltip: "Receipt Date",
    //   field: "receiptDate",
    //   hide: false,
    //   valueFormatter: function (params: any) {
    //     return formatDatePipe(params.value);
    //   },
    //   comparator: customComparator,
    //   filter: "agDateColumnFilter",
    //   menuTabs: ["filterMenuTab"],
    //   filterParams: {
    //     suppressAndOrCondition: true,
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //   },
    // },
    {
      headerName: "Status",
      headerTooltip: "Status",
      field: "status",
      hide: false,
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Inventory Date",
      headerTooltip: "Inventory Date",
      field: "inventoryDate",
      hide: false,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    },
    {
      headerName: "Inventoried By",
      headerTooltip: "Inventory By",
      field: "inventoriedBy",
      hide: false,
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
  ];
  const localdata: any = localStorage.getItem("FormDetailCol");
  const [colDefs, setColDefs] = useState(columnItems);
  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setTimeout(() => {
      params.api.autoSizeAllColumns();
    }, 100);
  };
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    localStorage.setItem("FormDetailCol", JSON.stringify(columnValues));
    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const FormDetails = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageSize: gridData.pageSize,
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              GlobalSearch: gridData.globalSearch,
              IsForExcel: gridData.isForExcel,
              SortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(get222FormDetails(data)).then((result: any) => {
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
              updateGridData("rows", result?.payload?.data?.form222);
              if (result?.payload?.data?.form222?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.form222,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        FormDetails();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      FormDetails();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs),formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange =() => {
    if(gridApi) {
        updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  
  return (
    <Container fluid>
      <PageTitle
        name="222 Form Details"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body grid-reset">
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
          autoSizeStrategy={{
            type: "fitCellContents",
            colIds: [
              "orderNumber",
              "ndc",
              "inventoriedBy"
            ],
          }}
        ></GridComponent>
      </div>
    </Container>
  );
}
export default FormDetail;
